<template>
  <n-grid cols="1 s:10" responsive="screen" style="padding-top: 30px">
    <n-gi span="8" offset="0 s:1">
      <n-space justify="start">
        <div :class="currentBalance === '$0.00' ? 'pb-4' : ''">
          {{ t("balance.labels.currentBalance", { currentBalance: currentBalance }) }}
        </div></n-space
      >
      <Table
        :data="transactions"
        :itemsActions="itemActions"
        :globalActions="globalActions"
        :fieldsToHide="['id', 'userId', 'rentId']"
        :fieldsTooLong="fieldsTooLong"
        :filterableFields="filterableFields"
      /> </n-gi
  ></n-grid>
</template>
<script>
import { defineComponent, computed, onMounted, h, ref, watch } from "vue";
import { EyeOutline as ViewIcon } from "@vicons/ionicons5";
import { Money as WithdrawalIcon } from "@vicons/carbon";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { NGrid, NGi, NSpace, useDialog, useMessage, NAlert } from "naive-ui";
import Table from "@/components/TableBeta.vue";
import { floatToCurrency, formatDates } from "@/shared/utils";
import * as Big from "js-big-decimal";
import { parseISO, compareAsc } from "date-fns";
import PayoutProfileUpdate from "@/components/balance/PayoutProfileUpdate";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: { Table, NGrid, NGi, NSpace },
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: "global",
    });
    const dialog = useDialog();
    const message = useMessage();
    const router = useRouter();
    const store = useStore();
    const currentUser = computed(() => store.state.user.userFromDB);
    const transactionsLoadedRef = ref(false);

    onMounted(async () => {
      if (currentUser.value?.id && !transactionsLoadedRef.value) {
        loadTransactions().then(() => (transactionsLoadedRef.value = true));
      }
    });

    watch(currentUser, async (newValue) => {
      if (newValue.id && !transactionsLoadedRef.value) {
        loadTransactions().then(() => (transactionsLoadedRef.value = true));
      }
    });

    const loadTransactions = async () => {
      await store.dispatch("app/lockUI");
      await store.dispatch(
        "maintainer_balance/fillBalanceTransactions",
        currentUser.value.id
      );
      await store.dispatch("app/unlockUI");
    };

    const fieldsTooLongRef = computed(() => [t("commons.labels.description")]);

    const filterableFieldsRef = computed(() => [
      t("commons.labels.type"),
      t("commons.labels.status"),
    ]);

    const transactionsRef = computed(
      () => store.state.maintainer_balance.balanceTransactions
    );

    const currentBalanceRef = computed(() => {
      return parseFloat(
        transactionsRef.value
          .reduce(
            (partial, transaction) => partial.add(new Big(transaction.amount)),
            new Big(0)
          )
          .getValue()
      );
    });

    const withdrawalProcess = () => {
      const d = dialog.info({
        title: t("balance.dialogs.withdrawalConfirmation.title"),
        content: t("balance.dialogs.withdrawalConfirmation.content", {
          currentBalance: floatToCurrency(currentBalanceRef.value),
        }),
        positiveText: t("commons.confirmation.yes"),
        negativeText: t("commons.confirmation.no"),
        onPositiveClick: () => {
          d.loading = true;
          d.closable = false;
          d.maskClosable = false;
          store.dispatch("app/lockUI");
          const transaction = {
            description: "Withdrawal requested by user",
            amount: currentBalanceRef.value * -1,
            user: currentUser.value.id,
          };
          return new Promise((resolve) => {
            store
              .dispatch("maintainer_balance/addProfitTransactionToBalance", transaction)
              .then(() => {
                message.success(t("balance.messages.withdrawalRegistered"));
                store.dispatch("notifications/FireNotification", {
                  userId: currentUser.value.id,
                  fromUserId: currentUser.value.id,
                  title: "Withdrawal registered",
                  message: `${t("balance.notifications.withdrawalRegistered", {
                    amount: floatToCurrency(transaction.amount * -1),
                  })}\n`,
                  targetRoute: {
                    name: "my-profits",
                    params: [],
                  },
                });
              })
              .catch((error) => {
                console.log(error);
                message.error(t("balance.messages.errorRegisteringWithdrawal"));
              })
              .then(resolve)
              .finally(() => store.dispatch("app/unlockUI"));
          });
        },
      });
    };

    const registPayoutAccountInfo = () => {
      const payoutProfileRef = ref(null);
      const d = dialog.warning({
        title: t("balance.messages.completeYourPayoutProfile.title"),
        content: () =>
          h("div", {}, null, [
            h(
              NAlert,
              { type: "warning", showIcon: false },
              {
                default: () => t("balance.messages.completeYourPayoutProfile.content"),
              }
            ),
            h(
              PayoutProfileUpdate,
              {
                value: payoutProfileRef.value,
                "onUpdate:value": (value) => {
                  payoutProfileRef.value = value;
                },
                standalone: true,
                onCanContinue: (value) => {
                  console.log("onCanContinue", value);
                  d.positiveButtonProps.disabled = !value;
                },
              },
              {}
            ),
          ]),
        positiveText: t("balance.messages.completeYourPayoutProfile.positiveButton.text"),
        positiveButtonProps: { disabled: true },
        negativeText: t("commons.actions.cancel"),
        onPositiveClick: () => {
          d.loading = true;
          d.closable = false;
          d.maskClosable = false;
          store.dispatch("app/lockUI");
          return new Promise((resolve) => {
            store;
            store
              .dispatch("user/updatePayoutProfile", payoutProfileRef.value)
              .then(() => {
                message.success(t("balance.messages.payoutProfileRegistered"));
                d.destroy();
                withdrawalProcess();
              })
              .catch((error) => {
                console.log(error);
                message.error(t("balance.messages.errorRegisteringPayoutProfile"));
              })
              .then(resolve)
              .finally(() => store.dispatch("app/unlockUI"));
          });
        },
      });
    };

    return {
      t,
      itemActions: (transaction) => {
        let actions = [];
        // Action to go to rent details
        actions.push({
          label: t("balance.itemActions.labels.rentDetails"),
          key: "rent",
          icon: ViewIcon,
          props: {
            onClick: () => {
              router.push({
                name: "view-rent-detail",
                params: { id: transaction.rentId },
              });
            },
          },
        });

        return actions;
      },
      globalActions: computed(() => {
        return currentBalanceRef.value > 0
          ? [
              {
                label: t("balance.actions.labels.withdraw"),
                key: "withdraw",
                icon: WithdrawalIcon,
                props: {
                  onClick: currentUser.value.payoutProfile?.externalId
                    ? withdrawalProcess
                    : registPayoutAccountInfo,
                },
              },
            ]
          : null;
      }),
      transactions: computed(() =>
        transactionsRef.value
          .map((transaction) => {
            return {
              id: transaction.id,
              userId: transaction.userId,
              rentId: transaction.rentId,
              [t("commons.labels.date")]: formatDates(transaction.date),
              [t("commons.labels.type")]: t(`balance.types.${transaction.type}`),
              [t("commons.labels.description")]: transaction.description,
              [t("commons.labels.status")]: t(`balance.status.${transaction.status}`),
              [t("commons.labels.amount")]: floatToCurrency(transaction.amount),
            };
          })
          .sort((a, b) => {
            return compareAsc(parseISO(a.createdAt), parseISO(b.createdAt));
          })
      ),
      currentBalance: computed(() => floatToCurrency(currentBalanceRef.value)),
      fieldsTooLong: fieldsTooLongRef,
      filterableFields: filterableFieldsRef,
    };
  },
});
</script>
